/* eslint-disable @next/next/no-img-element */
import React, { FC } from "react";
import { GetServerSideProps } from "next";
import RockstarHeader from "components/RockstarHeader";
import RockstarFooter from "components/RockstarFooter";
import { ArticleBySlugQuery, getSdk } from "services/datocms/generated";
import { createDatoClient } from "services/datocms";
import DatoNativeHeadTagsRenderer from "components/DatoNativeHeadTagsRenderer";
import ArticleRowRenderer from "components/ArticleRowRenderer";
import TopCtaBanner from "components/TopCtaBanner";

interface PostPageProps {
  post: ArticleBySlugQuery["article"];
}

export const getServerSideProps: GetServerSideProps<PostPageProps> = async ({
  params,
  ...rest
}) => {
  const slug = params?.slug;

  try {
    const { article } = await getSdk(
      createDatoClient(rest.preview)
    ).ArticleBySlug({ slug: slug as string });

    if (!article) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        post: article,
      },
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
};

const Slug: FC<PostPageProps> = ({ post }) => {
  return (
    <>
      {post?._seoMetaTags && (
        <DatoNativeHeadTagsRenderer seoTags={post?._seoMetaTags} />
      )}

      <div id="page" className="site">
        <a className="skip-link screen-reader-text" href="#content">
          Skip to content
        </a>

        <div className="site-header-block">
          <TopCtaBanner />
          <RockstarHeader />
        </div>

        <div id="content" className="site-content">
          <div id="primary" className="content-area">
            <main id="main" className="site-main" role="main">
              <article
                id="post-1216"
                className="post-1216 athletes type-athletes status-publish has-post-thumbnail hentry tag-chadkerley AthleteCategories-bmx entry"
              >
                <div className="article-post">
                  <header className="entry-header alignwide">
                    <div className="container">
                      <h1 className="entry-title">{post?.title}</h1>{" "}
                    </div>
                  </header>
                  <div className="entry-content">
                    {post?.heroImage?.url && (
                      <div className="container">
                        <figure className="post-thumbnail">
                          <img
                            width="1568"
                            height="515"
                            src={post?.heroImage?.url}
                            className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                            alt="spotlight studios"
                            sizes="(max-width: 1568px) 100vw, 1568px"
                            style={{
                              width: "100%",
                              height: "32.84%",
                              maxWidth: "1620px",
                            }}
                          />{" "}
                        </figure>
                      </div>
                    )}
                    <div className="content-rows">
                      {post?.contentRow?.map((row) => (
                        <ArticleRowRenderer key={row?.id} row={row} />
                      ))}{" "}
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>

        <RockstarFooter />
      </div>
    </>
  );
};

export default Slug;
