/* eslint-disable @next/next/no-img-element */
import React, { FC } from "react";
import { StructuredText } from "react-datocms";
import cx from "classnames";
import { PostRowFragment, VideoRowFragment } from "services/datocms/generated";

const ArticleRowRenderer: FC<{
  row: PostRowFragment | VideoRowFragment;
}> = ({ row }) => {
  const rowFragment = row as PostRowFragment;
  const rowHasImage = !!rowFragment.image?.url;

  if (!rowHasImage || rowFragment?.textPosition === "Left") {
    return (
      <div className="post-image-text" id="post-image-text-block_61d6e1c6e0bc1">
        <div className="column-grid-item container item-aligment-right">
          <div className={cx("grid-item-details", rowHasImage && "grid-item")}>
            <div className="item-details">
              <StructuredText data={rowFragment?.content?.value} />
            </div>
          </div>
          {rowHasImage && (
            <div className="grid-item grid-item-img">
              <img src={rowFragment?.image?.url} alt="" />
            </div>
          )}
        </div>
      </div>
    );
  } else if (rowFragment?.textPosition === "Right") {
    return (
      <div className="post-image-text" id="post-image-text-block_622fca2536f98">
        <div className="column-grid-item container item-aligment-left">
          <div className="grid-item grid-item-details">
            <div className="item-details">
              <StructuredText data={rowFragment?.content?.value} />
            </div>
          </div>
          {rowHasImage && (
            <div className="grid-item grid-item-img">
              <img src={rowFragment?.image?.url} alt="" />
            </div>
          )}
        </div>
      </div>
    );
  } else if ((row as VideoRowFragment)?.mp4?.url) {
    return (
      <figure className="wp-block-video">
        <video
          controls={true}
          src={(row as VideoRowFragment)?.mp4?.url}
        ></video>
      </figure>
    );
  }

  return <div></div>;
};

export default ArticleRowRenderer;
