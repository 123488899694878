import React, { FC } from "react";
import Head from "next/head";
import { CategoryBySlugQuery } from "services/datocms/generated";

const DatoNativeHeadTagsRenderer: FC<{
  seoTags: CategoryBySlugQuery["allCategories"][number]["_seoMetaTags"];
}> = ({ seoTags }) => {
  return (
    <Head>
      {seoTags?.map((tag) => {
        if (tag.tag === "title" && tag?.content !== null) {
          return <title key={tag.content}>{tag.content}</title>;
        } else if (tag?.attributes !== null) {
          return (
            <meta
              key={tag?.attributes?.content}
              property={tag?.attributes?.property}
              content={tag?.attributes?.content}
            />
          );
        }
      })}
    </Head>
  );
};

export default DatoNativeHeadTagsRenderer;
